<template>
  <div class="detail-page">
    <HeaderComponent />
    <div class="detail-page-main">
      <template v-if="detail && detail.filename">
        <div class="page-middle">
          <div class="tag-wrap">
            <el-tag type="success" effect="plain">网盘资源</el-tag>
            <el-tag effect="plain" type="danger">用户分享</el-tag>
          </div>
          <h1>{{ detail.filename }}</h1>
        </div>
        <div class="detail-page-inner">
          <div class="avatar-info">
            <img :src="avatar" />
          </div>
          <div class="detail-content">
            <div class="username-info">
              <h4 class="username">{{ nickname }}</h4>
              <p class="createtime">{{ detail.utime }}</p>
            </div>
            <div
              class="detail-wrap"
              v-if="detail.filelist && detail.filelist.length > 1"
            >
              <template v-for="(detailItem, detailIndex) in detail.filelist">
                <p
                  class="detail-item"
                  v-if="detailIndex > 0"
                  :key="`detail${detailIndex}`"
                  v-html="detailItem.filename"
                />
              </template>
            </div>
            <p class="download-link">
              <a :href="detail.url" target="_blank">{{ detail.url }}</a>
              <br />
              <span v-if="pwd">提取码：{{ pwd }}</span>
            </p>
          </div>
        </div>
      </template>
      <div class="loading-wrap" v-if="loading">
        <lottie-player
          class="animation"
          src="https://res.yimiaopan.com/pan/lottie/search.json"
          background="transparent"
          speed="0.4"
          loop
          autoplay
        />
      </div>
      <div class="no-result" v-if="!loading && (!detail || !detail.filename)">
        <p>内容已被管理员删除！</p>
      </div>
    </div>
    <FooterComponent style="margin-top: 20px" />
  </div>
</template>
<script>
import HeaderComponent from "./Header.vue";
import FooterComponent from "./Footer.vue";
import { getDetail } from "../../api/search";
import { randomAvatar, randomNickname } from "../../utils/index";
console.log(randomAvatar(), randomNickname());
const formatUrl = (url) => {
  if (!url) {
    return "";
  }
  const index = url.lastIndexOf("http");
  if (index > 0) {
    return url.slice(index);
  } else {
    return url;
  }
};

export default {
  components: {
    HeaderComponent,
    FooterComponent,
  },
  data: () => ({
    id: "",
    pwd: "",
    url: "",
    type: "",
    parent: "",
    detail: null,
    loading: true,
    avatar: randomAvatar(),
    nickname: randomNickname(),
  }),
  async mounted() {
    this.id = this.$route.params.id;
    this.type = this.$route.query.type;
    this.parent = this.$route.query.parent || "";
    if (this.id) {
      await this.getResourceDetail();
    }
  },
  methods: {
    async getResourceDetail() {
      this.loading = true;
      if (this.id) {
        this.detail = await getDetail({
          id: this.id,
          type: this.type,
          parent: this.parent,
          size: 15,
        });
        if (this.detail && this.detail.filename) {
          this.pwd = this.detail.pwd;
        }
        if (this.detail && this.detail.url) {
          this.url = formatUrl(this.detail.url);
          this.getResourceUrl();
        }
      }
      this.loading = false;
    },

    async getResourceUrl() {
      if (
        this.type === "aliyundrive" &&
        this.parent &&
        this.parent != "undefined"
      ) {
        this.url = `${this.url}/folder/${this.parent}`;
      }
      if (this.pwd) {
        if (this.url.lastIndexOf("?") > -1) {
          this.url = `${this.url}&pwd=${this.pwd}`;
        } else {
          this.url = `${this.url}?pwd=${this.pwd}`;
        }
      }

      if (this.url.lastIndexOf("?") > -1) {
        this.url = `${this.url}&entry=dalipan`;
      } else {
        this.url = `${this.url}?entry=dalipan`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.detail-page {
  min-height: 100vh;
  .detail-page-main {
    min-height: calc(100vh - 300px);
  }
}
.page-middle {
  background-color: #009900;
  padding: 75px 20px;
  color: #fff;
  text-align: center;
  .tag-wrap {
    margin-bottom: 10px;
    .el-tag {
      font-size: 15px;
      font-weight: bold;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

.no-result {
  width: $theme-width;
  max-width: 100%;
  margin: 0 auto;
  padding: 100px 0 60px;
  font-size: 20px;
  text-align: center;
  color: #666;
  border-bottom: 1px solid #e4ebf6;
}
.detail-page-inner {
  width: $theme-width;
  max-width: 100%;
  margin: 0 auto;
  padding: 30px 0 60px;
  font-size: 14px;
  color: #666;
  display: flex;
  border-bottom: 1px solid #e4ebf6;
  .avatar-info {
    flex: none;
    margin-right: 20px;
    img {
      height: 60px;
      border-radius: 50%;
      border: 1px solid #e4ebf6;
    }
  }
  .detail-wrap {
    color: #667;
    line-height: 1.8;
    font-size: 13px;
  }
  .download-link {
    margin-top: 20px;
  }
  a {
    color: #4876db;
    border-bottom: 1px solid #e4ebf6;
    &:hover {
      border-color: #4876db;
    }
  }
  .username-info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .username {
      margin-right: 10px;
    }
    .createtime {
      font-size: 12px;
      color: #999;
    }
  }
}
.loading-wrap {
  text-align: center;
  .animation {
    height: 160px;
  }
}
</style>
