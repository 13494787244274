<template>
  <footer class="footer">
    <div class="copyright-wrap">
      <p>本站不储存任何资源，所有资源均来自用户自愿分享。</p>
      <p>Copyright © {{ year }}. All Rights Reserved.</p>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style lang="scss" scoped>
.copyright-wrap {
  font-size: 12px;
  color: #666;
  text-align: center;
  padding: 15px;
  p {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
